import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../layouts/CleanPage';
import { pushTag } from '../../utils/gtm';
import './index.scss';

const DashboardPage = () => (
  <Layout>
    <Helmet
      title="Sell Like a Bro"
      meta={[
        { name: 'robots', content: 'noindex,nofollow' },
      ]}
      htmlAttributes={
        { class: 'sell-like-bro' }
      }
    />

    <div className="sell-like-bro__content">
      <div className="sell-like-bro__text">
        This is a sign <br />
        that you received this link <br />
        from your classmate
      </div>
      <div className="sell-like-bro__yes__block">
        <button type="button" className="sell-like-bro__yes__button" onClick={() => { window.location.href = '/?utm_source=tap-me&utm_medium=landing&utm_campaign=StudyBuddyReferral'; }}>Tap if so</button>
      </div>
      <div className="sell-like-bro__no__block">
        <button type="button" className="sell-like-bro__no__button" onClick={() => { pushTag('event', 'Close'); window.location.href = 'https://t.me/studbudd'; }}>Tap if no</button>
      </div>
    </div>

    <div className="sell-like-bro__effect">
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
      <div className="tri" />
    </div>
  </Layout>
);

export default DashboardPage;
