import React from 'react';
import PropTypes from 'prop-types';

const LayoutForCleanPage = ({ children }) => (
  <div className="page-wrapper">
    {children}
  </div>
);

LayoutForCleanPage.propTypes = {
  children: PropTypes.node,
};

LayoutForCleanPage.defaultProps = {
  children: undefined,
};

export default LayoutForCleanPage;
